<template>
  <form class="k-form signUp findPasswordStep2 pd0" id="signupForm" @submit.prevent="onSubmit(authNumber)">
<!--    <p class="etext">인증번호를 수신할 이메일을 입력해 주세요.</p>-->
    <fieldset>
      <div class="k-form-field">
        <span>{{ email }}</span>
<!--        <input  type="email"-->
<!--                ref="emailRef"-->
<!--                class="k-textbox"-->
<!--                v-model.trim="email"-->
<!--                autofocus-->
<!--                placeholder="이메일" />-->
      </div>
      <input type="button" value="인증번호 전송" class="k-button mg0 mgt10" @click="sendAuth(email)">
      <label class="k-form-field">
        <input  type="text"
                ref="authNumberRef"
                class="k-textbox"
                maxlength="4"
                v-model.trim="authNumber"
                autofocus
                placeholder="인증번호 4자리 입력"
        />
      </label>
      <span id="leftTime"></span>
      <input type="submit" value="인증" class="mid_btn orange login_btn mt10" style="cursor: pointer;">
    </fieldset>
  </form>
</template>

<script>
  import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
  import ApiUtil from '@/api/api.util'
  import ApiConfig from '@/api/api.config'
  export default {
    name: 'FindPasswordStep2',
    props: {
      param: {
        type: Object,
        required: false
      }
    },
    methods: {
      // 인증번호 전송
      sendAuth (email) {
        const vm = this

        // if(!email.trim()) {
        //   kendo.alert('이메일을 확인해 주세요.')
        // }
        /* if(!EtnersCommonUtil.validCheckEmail(email.trim())) {
          kendo.alert('이메일 형식을 확인해 주세요.').bind('close', function() { vm.$refs.emailRef.focus() })
          return false
        }*/

        this.$store.dispatch('FETCH_SEND_AUTH', { userId: this.$props.param.userId, transferMethod: 'email' }).then(data => {

          kendo.alert('인증번호가 전송되었습니다.<br>유효시간은 2분 30초입니다.')

          // 남은 시간
          let leftSec = 150,
              display = document.querySelector('#leftTime')
          // 이미 타이머가 작동중이면 중지
          if (this.isTimerRunning) {
            clearInterval(this.leftTimer)
          }
          // 인증 유효시간 출력
          this.showLeftTime(leftSec, display)
          // data setting
          this.authNumberComfirmBoolean = false
          this.authNumber = null
          // this.authNumberServer = data.resultData.authNo
          this.authNumberComfirmMsg = '<span class="msg_green"></span>'
        })
      },
      // 인증 유효시간 출력(1초단위)
      showLeftTime (count, display) {
        const vm = this
        let minutes, seconds
        this.isTimerRunning = true
        this.leftTimer = setInterval(function () {
          minutes = parseInt(count / 60, 10)
          seconds = parseInt(count % 60, 10)
          minutes = minutes < 10 ? '0' + minutes : minutes
          seconds = seconds < 10 ? '0' + seconds : seconds

          if (!vm.authNumberComfirmBoolean) {
            display.textContent = '\xa0\xa0\xa0' + minutes + ':' + seconds
            if (--count < 0) {
              clearInterval(vm.leftTimer)
              display.textContent = '시간초과'
              display.style.color = '#ff0000'
              this.isTimerRunning = false
              // this.authNumberServer = null
              return false
            }
          } else {
            clearInterval(vm.leftTimer)
            display.textContent = ''
            this.isTimerRunning = false
            return false
          }
        }, 1000)
      },
      // 인증 확인
      onSubmit (authNumber) {
        const vm = this

        if(!vm.authNumber) {
          kendo.alert('인증번호 4자리를 입력해 주세요').bind('close', function() {
            vm.$refs.authNumberRef.focus()
          })
          return false
        }
        ApiUtil.query(`${ApiConfig.efsDomain}/efs/user/confirm-auth-number`,
            { userId : this.userId, authNo : vm.authNumber }).then(response => {

          if(response.data.resultStatus.messageCode === '2000') {
            kendo.alert('인증되었습니다.')
            this.$emit('child', { userId: this.userId })
          }else {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }
        })
      },
    },
    data () {
      return {
        userId: this.$props.param.userId,
        spTelNo: this.$props.param.spTelNo,
        email: this.$props.param.email,
        authNumber: '',
        authNumberServer: null,
        authNumberComfirmBoolean: false,
        authNumberComfirmMsg: '<span class="msg_green"></span>',
        leftTimer: null,
        isTimerRunning: false,
      }
    },
  }


</script>
